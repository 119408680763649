@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;1,400;1,700&display=swap');
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';

html, body {
    height: 100%;
    background: #fff;
    color: #111;
    font-family: 'Source Sans Pro', vaerdana, tahoma, arial, sans-serif;
    font-size: 16px;
    overflow-wrap: break-word;
    line-height: 1.6;
}
h2 {
	border-bottom: 1px solid #001338;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-size: 24px;
}
.btn {
	color: white;
    background: #001338;
    text-transform: uppercase;
    border: 0px;
    border-radius: 0;
	font-weight: 700;
    font-size: 16px;
    padding: 10px 50px!important;
    display: block;
	}
.btn:hover {
	background: #0e2551;
}
.vid .col-sm-6 {
	padding: 15px;
}
.vid img {
	width: 100%;
}
.no_padd {
	padding: 0px!important;
}
#start h1, #start p, #page h1, #page p {
	color: white;
}
#info {
	display: block!important;
}
#start h1 {
	padding-top: 200px;
}

#page h1 {
	padding-top: 40px;
}
#categories {
	color: white;
	background: #001338;
	padding: 10px 0;
}

#categories a {
	color: white;
}
.input[type="text"] {
	 border-radius: 0;
	 border: 1px solid #001338;
}
.text-muted.small {
	padding: 2px 5px!important;
    font-size: 11px;
    border: 1px solid #ed1b24;
    display: inline-block;
    margin-bottom: 10px;
}
.nav-link {
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	padding: 0.5rem 40px;
	color: #333333!important;
}	

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
}

.cookies {
    font-family: Tahoma, Geneva, sans-serif;
    font-size: small;
    position: fixed;
    bottom: 0;
    z-index: 9999;
}

.cookies p { margin:0;}

.navbar {
    background: #fff;
    border-top: 2px solid #007bff;
    font-weight: 600;
    transition: box-shadow .2s;
    padding: 0;
	position: fixed;
	z-index: 999;
	width: 100%;
	top: 0px;
}

.navbar-brand img {
    max-width: 3rem;
}

.box {
   display: block;
}

.box-half {
    min-height: 50%;
}

#start {
    background: url('/img/background.jpg') no-repeat center;
    background-size: cover;
    text-shadow: 2px 2px 8px rgb(205, 205, 205);
	min-height: 490px;
	margin: 0px!important;
}

#page {
	min-height: 200px;
	background: url('/img/head.jpg') no-repeat center;
    background-size: cover;
    text-shadow: 2px 2px 8px rgb(205, 205, 205);
	margin: 0px!important;
	margin-top: 60px!important;
}
#footer {
	background: url('/img/footer.jpg') no-repeat center;
	background-repeat: no-repeat;
	background-color: white;
}
#clients {
    color: #eee;
    padding-top: 2rem;
    padding-bottom: 1rem;
}
.red_bar {
	    text-transform: uppercase;
    font-size: 18px;
    padding-left: 40px;
    background-image: url(/img/red_bar.png);
    font-weight: 700;
    background-repeat: no-repeat;
    background-position: left center;
}
.dark_blue {
	background: #001338;
	color: white;
}
.dark_blue h3, .dark_blue p {
	color: white;
}
.part {
	display: flex;
    align-items:center;
}
.boxes {
	color: #252525;
	display: flex;
    align-items:center;
}
.bord {
	border-bottom: 2px solid #ed1b24;
    display: inline;
	color: black;
    font-weight: 600;
}
.card-deck {
	margin-bottom: 40px;
}
#infobar .text-left p {
	margin-bottom: 3px;
	margin-top: 3px;
	font-size: 14px;
}
#infobar {
	color: white;
	background-color: #001338;
}
.burg {
	color:#ed1b24;
}
.social a {
	float: left;
	margin-right: 15px;
}
#footer .text-left p {
	margin-bottom: 3px;
	margin-top: 3px;
	font-size: 12px;
}
#footer a {
    color: #002060!important;
}

#footer a:hover {
    color: #eee;
}
#footer .nav-link {
	padding: 0px 10px!important;
}
@keyframes opacity {
    0% { opacity: 0;}
    100% { opacity: 1;}
}
.input[type="text"], select, textarea, .form-control {
	 border-radius: 0!important;
	 border: 1px solid #001338!important;
}
.form-control:focus {
	box-shadow: 0 0 0 1px rgba(0, 19, 56, 0.24);
}